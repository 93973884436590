export { default as Adult } from './adult.svg';
export { default as Arrow } from './arrow.svg';
export { default as BlackScienceLogo } from './blackScienceLogo.svg';
export { default as Burger } from './burger.svg';
export { default as Chevron } from './chevron.svg';
export { default as Close } from './close.svg';
export { default as Eighteen } from './eighteen.svg';
export { default as Quote } from './quote.svg';
export { default as SearchIcon } from './search.svg';
export { default as SearchArrow } from './searchArrow.svg';
export { default as SearchIconSmall } from './searchIcon.svg';
export { default as Share } from './share.svg';
export * from './socials';
export { default as SportLogo } from './sportLogo.svg';
export { default as SportLogoText } from './sportLogoText.svg';
export { default as TestEmpty } from './testEmpty.svg';
export { default as TestGreen } from './testGreen.svg';
export { default as TestRed } from './testRed.svg';
