import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '~constants';

interface ButtonProps {
  $blackScience?: boolean;
  $borderWidth?: CSSProperties['borderWidth'];
  $fontSize?: CSSProperties['fontSize'];
  $height?: CSSProperties['height'];
  $left?: CSSProperties['left'];
  $lineHeight?: CSSProperties['lineHeight'];
  $margin?: CSSProperties['margin'];
  $position?: CSSProperties['position'];
  $right?: CSSProperties['right'];
  $sport?: boolean;
  $width?: CSSProperties['width'];
}

type Props = ButtonProps & { [key in Screen]?: ButtonProps };

export const HugeButton = styled.a<Props>`
  height: ${propOr('105px', '$height')};
  width: ${propOr('100%', '$width')};
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: ${propOr('32px', '$fontSize')};
  line-height: ${propOr('110%', '$lineHeight')};
  left: ${propOr('unset%', '$left')};
  right: ${propOr('unset', '$right')};
  position: ${propOr('relative', '$position')};
  margin: ${propOr('0', '$margin')};
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--color-white);
  z-index: 10;

  &:hover {
    background-color: var(--color-primary-light);
  }

  ${(props) =>
    props.$blackScience &&
    css`
      background-color: var(--color-black-science);

      &:hover {
        background-color: var(--color-black-science-light);
      }
    `}

  ${(props) =>
    props.$sport &&
    css`
      background-color: var(--color-na-sporte);

      &:hover {
        background-color: var(--color-na-sporte-light);
      }
    `}

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        props[field as Screen] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$fontSize'], props)};
            height: ${pathOr(null, [field, '$height'], props)};
            width: ${pathOr(null, [field, '$width'], props)};
            line-height: ${pathOr(null, [field, '$lineHeight'], props)};
            margin: ${pathOr(null, [field, '$margin'], props)};
            left: ${pathOr(null, [field, '$left'], props)};
            right: ${pathOr(null, [field, '$right'], props)};
          }
        `,
    )}
`;
