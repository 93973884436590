import { pathOr, pipe, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '~constants';
import { getColor, TextColor } from '~utils';

type TextProps = {
  $alignItems?: CSSProperties['alignItems'];
  $background?: CSSProperties['background'];
  $border?: CSSProperties['border'];
  $borderColor?: CSSProperties['borderColor'];
  $borderRadius?: CSSProperties['borderRadius'];
  $color?: TextColor | null;
  $cursor?: CSSProperties['cursor'];
  $dash?: boolean;
  $display?: CSSProperties['display'];
  $errorLink?: boolean;
  $float?: CSSProperties['float'];
  $gap?: CSSProperties['gap'];
  $gridArea?: CSSProperties['gridArea'];
  $hoverBackground?: CSSProperties['background'];
  $hoverColor?: CSSProperties['color'];
  $htmlLineClamp?: number;
  $htmlLineClampNoP?: number;
  $hyphens?: CSSProperties['hyphens'];
  $innerPMarginBlock?: CSSProperties['marginBlock'];
  $innerPMarginBottom?: CSSProperties['marginBottom'];
  $innerPMarginTop?: CSSProperties['marginTop'];
  $justifyContent?: CSSProperties['justifyContent'];
  $letterSpacing?: CSSProperties['letterSpacing'];
  $lineHeight?: CSSProperties['lineHeight'];
  $marginBottom?: CSSProperties['marginBottom'];
  $marginLeft?: CSSProperties['marginLeft'];
  $marginRight?: CSSProperties['marginTop'];
  $marginTop?: CSSProperties['marginTop'];
  $maxHeight?: CSSProperties['maxHeight'];
  $maxWidth?: CSSProperties['maxWidth'];
  $newsItemText?: 'visible' | 'hidden';
  $newsItemTitle?: string;
  $opacity?: CSSProperties['opacity'];
  $padding?: CSSProperties['padding'];
  $position?: CSSProperties['position'];
  $size?: number;
  $spaceBetweenParagraph?: string;
  $spanBackground?: CSSProperties['background'];
  $spanColor?: CSSProperties['color'];
  $textAlign?: CSSProperties['textAlign'];
  $textShadow?: CSSProperties['textShadow'];
  $textTransform?: CSSProperties['textTransform'];
  $textWithLink?: boolean | CSSProperties['color'];
  $transition?: CSSProperties['transition'];
  $weight?: number;
  $whiteSpace?: CSSProperties['whiteSpace'];
  $zIndex?: CSSProperties['zIndex'];
};

type Props = TextProps & { [key in Screen]?: TextProps };

export const Text = styled.span<Props>`
  font-size: ${propOr(14, '$size')}px;
  line-height: ${propOr(15, '$lineHeight')}px;
  font-weight: ${propOr('normal', '$weight')};
  color: ${pipe(propOr(null, '$color'), getColor)};
  cursor: ${propOr(null, '$cursor')};
  margin-bottom: ${propOr(null, '$marginBottom')};
  margin-top: ${propOr(null, '$marginTop')};
  margin-right: ${propOr(null, '$marginRight')};
  margin-left: ${propOr(null, '$marginLeft')};
  letter-spacing: ${propOr(null, '$letterSpacing')};
  text-shadow: ${propOr(null, '$textShadow')};
  opacity: ${propOr(null, '$opacity')};
  text-align: ${propOr(null, '$textAlign')};
  max-height: ${propOr(null, '$maxHeight')};
  max-width: ${propOr(null, '$maxWidth')};
  text-transform: ${propOr(null, '$textTransform')};
  border: ${propOr(null, '$border')};
  border-color: ${pipe(propOr(null, '$borderColor'), getColor)};
  border-radius: ${propOr(null, '$borderRadius')};
  position: ${propOr(null, '$position')};
  background: ${pipe(propOr(null, '$background'), getColor)};
  padding: ${propOr(null, '$padding')};
  grid-area: ${propOr(null, '$gridArea')};
  display: ${propOr(null, '$display')};
  align-items: ${propOr(null, '$alignItems')};
  justify-content: ${propOr(null, '$justifyContent')};
  white-space: ${propOr(null, '$whiteSpace')};
  float: ${propOr(null, '$float')};
  hyphens: ${propOr(null, '$hyphens')};
  gap: ${propOr(null, '$gap')};
  z-index: ${propOr(null, '$zIndex')};

  ${(props) =>
    props.$dash &&
    css`
      & p::before {
        content: '---';
        letter-spacing: -4px;
        margin-right: 10px;
      }
    `}
  & p {
    margin-block: ${propOr(0, '$innerPMarginBlock')};
    margin-top: ${propOr(null, '$innerPMarginTop')};
    margin-bottom: ${propOr(null, '$innerPMarginBottom')};
  }

  & ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  & ol {
    margin-top: 0;
    margin-bottom: 0;
  }

  .highlight {
    background: var(--color-secondary);
  }

  .textTooltip {
    padding: 3px 8px 0;
    border-radius: 34px;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    color: var(--color-primary);
    display: inline;
    line-height: 24px;

    &:hover {
      border: 1px solid var(--color-black);
      color: var(--color-black);
    }
  }

  ${(props) =>
    props.$textWithLink &&
    css`
      & a {
        color: ${props.$textWithLink === true
          ? 'var(--color-primary)'
          : pipe(propOr(null, '$textWithLink'), getColor)};
        transition: all 0.2s ease-in-out;
        border-bottom: 1px solid
          ${props.$textWithLink === true
            ? 'var(--color-primary)'
            : pipe(propOr(null, '$textWithLink'), getColor)};

        &:hover {
          border-color: transparent;
        }
      }
    `}

  ${(props) =>
    props.$errorLink &&
    css`
      border-bottom: 2px solid var(--color-black);
      margin-bottom: -2px;
    `}

  ${(props) =>
    props.$spaceBetweenParagraph &&
    css`
      p + p {
        padding: ${propOr('15px 0 0', '$spaceBetweenParagraph')};
      }
    `}

  ${(props) =>
    props.$hoverColor &&
    css`
      &:hover {
        color: ${pipe(propOr(null, '$hoverColor'), getColor)};
      }
    `}

  ${(props) =>
    props.$hoverBackground &&
    css`
      &:hover {
        background: ${pipe(propOr(null, '$hoverBackground'), getColor)};
      }
    `}

  ${(props) =>
    props.$spanColor &&
    css`
      span {
        color: ${pipe(propOr(null, '$spanColor'), getColor)};
      }
    `}

  ${(props) =>
    props.$spanBackground &&
    css`
      span {
        background: ${pipe(propOr(null, '$spanBackground'), getColor)};
        padding-right: 3px;
        margin-right: 3px;
      }
    `}


  ${(props) =>
    props.$newsItemTitle === 'main' &&
    css`
      &:hover {
        span {
          background: white;
          color: black;
          padding-right: 3px;
          margin-right: 3px;
        }
      }
    `}

  ${(props) =>
    props.$newsItemTitle === 'tab' &&
    css`
      &:hover {
        span {
          background: black;
          color: white;
          padding-right: 3px;
          margin-right: 3px;
        }
      }
    `}


  ${(props) =>
    props.$htmlLineClampNoP &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${propOr(null, '$htmlLineClampNoP')};
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}

  ${(props) =>
    props.$htmlLineClamp &&
    css`
      p {
        display: -webkit-box;
        -webkit-line-clamp: ${propOr(null, '$htmlLineClamp')};
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    `}

  ${(props) =>
    props.$newsItemText === 'hidden' &&
    css`
      transition: opacity 0s ease-in-out, max-height 0.2s ease-in-out;
      max-height: 0;
      display: flex;
    `}

  ${(props) =>
    props.$newsItemText === 'visible' &&
    css`
      transition: opacity 0.2s ease-in-out, max-height 0.2s ease-in-out;
      max-height: 200px;
      display: inline;
    `}

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$size'], props)}px;
            line-height: ${pathOr(null, [field, '$lineHeight'], props)}px;
            font-weight: ${pathOr(null, [field, '$weight'], props)};
            text-align: ${pathOr(null, [field, '$textAlign'], props)};
            margin-bottom: ${pathOr(null, [field, '$marginBottom'], props)};
            margin-top: ${pathOr(null, [field, '$marginTop'], props)};
            padding: ${pathOr(null, [field, '$padding'], props)};
            color: ${getColor(pathOr(null, [field, '$color'], props))};
            max-width: ${pathOr(null, [field, '$maxWidth'], props)};
            white-space: ${pathOr(null, [field, '$whiteSpace'])};
            gap: ${pathOr(null, [field, '$gap'])};
          }
        `,
    )}
`;
