import { PostType, SectionPublic, SettingPublic } from 'pn-backend';
import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { SectionAlias } from '~constants';

type State = {
  // ToDo В будущем лэйаут будет характеризоваться SectionType & PageType
  layout: SectionAlias | PostType | string;
  sections: SectionPublic[];
  settings: SettingPublic;
};

export interface Context {
  dispatch?: Dispatch<Action<Partial<State>>>;
  state: State;
}

type Props = State;

export interface Action<T = unknown> {
  payload?: T;
  type: string;
}

const initialValues: State = {
  layout: SectionAlias.Main,
  sections: [],
  settings: {
    additional: '',
    address: '',
    certificate: {
      contentType: '',
      id: 0,
      meta: {
        height: 0,
        width: 0,
      },
      url: '',
    },
    certificateTitle: '',
    chiefEditor: '',
    copyright: '',
    email: '',
    founder: '',
    logo: {
      contentType: '',
      id: 0,
      meta: {
        height: 0,
        width: 0,
      },
      url: '',
    },
    primaryColor: '',
    siteTitle: '',
    socialNetworkLinks: [],
    socialNetworkShare: [],
  },
};

const reducer = (state: State, action: Action<Partial<State>>) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.payload };
    default: {
      return state;
    }
  }
};

export const StateContext = createContext<Context>({
  state: initialValues,
});

export const StateProvider: FC<PropsWithChildren<Props>> = ({
  children,
  layout,
  sections,
  settings,
}) => {
  const values = useMemo(() => ({ layout, sections, settings }), [layout, sections, settings]);
  const [state, dispatch] = useReducer(reducer, { ...initialValues, ...values });

  const value = useMemo(() => ({ dispatch, state }), [dispatch, state]);

  useEffect(() => {
    dispatch({ payload: values, type: 'UPDATE' });
  }, [values]);

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};
