import Link from 'next/link';
import { not } from 'ramda';
import React, { FC, useContext, useState } from 'react';

import { Close, SearchIcon, SportLogo, SportLogoText } from '~assets';
import { Grid, Header } from '~atoms';
import { DEFAULT_GRID_PROPS, MOBILE_HEADER_HEIGHT } from '~constants';
import { useSvg } from '~hooks';
import { StateContext } from '~providers';

import { SearchLine } from '../../SearchLine';

export const HeaderOnSport: FC = () => {
  const {
    state: { settings },
  } = useContext(StateContext);
  const { logo } = settings;

  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const toggleSearch = () => setIsOpenSearch(not);
  const closeSearch = () => setIsOpenSearch(false);

  const siteLogo = useSvg(logo?.url);

  return (
    <>
      <Header
        $backgroundColor="black"
        $sm={{
          $height: MOBILE_HEADER_HEIGHT,
        }}
      >
        <Grid
          $display="flex"
          $width="1200px"
          $justifyContent="space-between"
          $alignItems="center"
          $margin="0 auto"
          $xl={{ $padding: '0 15px', $width: '100%' }}
        >
          <Grid
            $width="193px"
            $height="25px"
            $color="white"
            $sm={{
              $height: '23px',
              $width: '177px',
            }}
          >
            <Link href="/" passHref>
              <div dangerouslySetInnerHTML={{ __html: siteLogo }} />
            </Link>
          </Grid>
          <Grid
            $display="block"
            $position="absolute"
            $width="32px"
            $left="calc(50% - 16px)"
            $sm={{
              $display: 'none',
            }}
          >
            <SportLogo />
          </Grid>
          <Grid $width="32px" $height="32px" $marginLeft="10px" $color="white" $cursor="pointer">
            <Grid
              $transform={isOpenSearch ? 'scale(0)' : 'scale(1)'}
              $opacity={isOpenSearch ? 0 : 1}
              $position={isOpenSearch ? 'absolute' : 'relative'}
              $transition="0.2s ease-in-out"
            >
              <SearchIcon id="searchIcon" onClick={toggleSearch} />
            </Grid>
            <Grid
              $width="32px"
              $transform={isOpenSearch ? 'scale(1)' : 'scale(0)'}
              $opacity={isOpenSearch ? 1 : 0}
              $position={isOpenSearch ? 'relative' : 'absolute'}
              $zIndex={isOpenSearch ? '-10' : '10'}
              $transition="0.2s ease-in-out"
            >
              <Close onClick={toggleSearch} />
            </Grid>
          </Grid>
        </Grid>
        <SearchLine isOpen={isOpenSearch} closeSearch={closeSearch} />
      </Header>
      <Grid
        {...DEFAULT_GRID_PROPS}
        $width="1400px"
        $maxWidth="1400px"
        $margin="0 auto -1px"
        $padding="35px 0 31px"
        $background="white"
        $xl={{ $maxWidth: 'unset', $width: '100%' }}
        $lg={{ $padding: '20px 0 29px' }}
        $sm={{ $padding: '20px 0 21px' }}
      >
        <Grid
          $margin="0 auto"
          $height="113px"
          $lg={{ $height: '65px' }}
          $sm={{ $height: '54px', $width: '240px' }}
        >
          <SportLogoText height="inherit" />
        </Grid>
      </Grid>
    </>
  );
};
