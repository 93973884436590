import { config } from '~services/config';
import { getSectionLink } from '~utils/links';

import { SectionAlias } from './sections';

export const DEFAULT_TITLE = 'Постньюс — объясняем новости';

export const DEFAULT_DESCRIPTION =
  'Новости, лонгриды и интервью, которые помогут разобраться в событиях вокруг нас.';

const defaultUrl = `${config.ORIGIN_URL}`;

export const LOGO = {
  '@context': 'http://schema.org',
  '@type': 'ImageObject',
  height: 192,
  url: `${defaultUrl}/android-chrome-192x192.png`,
  width: 192,
};

export const ORGANIZATION = {
  '@id': defaultUrl,
  '@type': 'NewsMediaOrganization',
  image: LOGO,
  logo: LOGO,
  masthead: `${defaultUrl}${getSectionLink(SectionAlias.About)}`,
  name: 'Постньюс',
  sameAs: [
    'https://vk.com/postnews',
    'https://t.me/postnewsru',
    'https://www.youtube.com/@PostNews',
  ],
  slogan: DEFAULT_TITLE,
  url: defaultUrl,
};
