import React, { FC } from 'react';

import { TestEmpty, TestGreen, TestRed } from '~assets';
import { Grid, Text } from '~atoms';
import { AnswerOptionProps } from '~types';
import { isDefined } from '~utils';

interface AnswerOptionPropsExtended extends AnswerOptionProps {
  hideRightAnswer: boolean;
  isSelected: boolean;
  rightAnswerCaption: string;
  selectedAnswer: number | null;
  setAnswer: (isRightAnswer: boolean) => void;
  setSelectedAnswer: (index: number) => void;
  wrongAnswerCaption: string;
}

export const TextAnswerOption: FC<AnswerOptionPropsExtended> = ({
  hideRightAnswer,
  index,
  isRightAnswer,
  isSelected,
  rightAnswerCaption,
  selectedAnswer,
  setAnswer,
  setSelectedAnswer,
  text,
  wrongAnswerCaption,
}) => {
  const handleAnswer = () => {
    if (!isSelected) {
      setSelectedAnswer(index);
      setAnswer(isRightAnswer);
    }
  };

  const isThisAnswerSelected = selectedAnswer === index;
  const getBorderColor = () => {
    if (hideRightAnswer && isThisAnswerSelected) return '2px solid var(--color-primary)';
    if (isThisAnswerSelected && isRightAnswer) {
      return '2px solid var(--color-alert)';
    }
    if (isThisAnswerSelected && !isRightAnswer) {
      return '2px solid var(--color-fail)';
    }
    return '1px solid var(--color-black)';
  };

  const caption = isRightAnswer ? rightAnswerCaption : wrongAnswerCaption;
  const showCaptionConds = isDefined(caption) && !hideRightAnswer && isThisAnswerSelected;

  return (
    <Grid $overflow="hidden" $transition="all 0.2s ease" $lg={{ $padding: '0 15px' }}>
      <Grid
        onClick={handleAnswer}
        $display="flex"
        $alignItems="center"
        $padding="15px 50px 15px 20px"
        $hoverColor={isSelected ? 'null' : 'white'}
        $hoverBackground={isSelected ? 'null' : 'primary'}
        $border={getBorderColor()}
        $hoverBorder={isSelected ? 'null' : '2px solid var(--color-black)'}
        $hoverPadding={isSelected ? 'null' : '14px 49px 14px 19px'}
        $borderRadius="50px"
        $cursor={isSelected ? 'auto' : 'pointer'}
        $transition="background-color 0.2s ease"
        $marginBottom="15px"
        $position="relative"
      >
        <Text
          $weight={500}
          $size={20}
          $lineHeight={22}
          $color={isSelected && !isThisAnswerSelected ? 'gray' : null}
          $sm={{
            $lineHeight: 22,
            $size: 18,
          }}
        >
          {text}
        </Text>

        <Grid
          $opacity={
            isThisAnswerSelected ||
            (isSelected && isRightAnswer && !hideRightAnswer) ||
            (hideRightAnswer && isThisAnswerSelected)
              ? '1'
              : '0'
          }
          $position="absolute"
          $right="15px"
        >
          {!hideRightAnswer && isRightAnswer && <TestGreen />}
          {!hideRightAnswer && !isRightAnswer && <TestRed />}
          {hideRightAnswer && <TestEmpty />}
        </Grid>
      </Grid>

      <Grid
        $maxHeight={showCaptionConds ? '400px' : '0'}
        $overflow="hidden"
        $transition="all 0.2s ease"
      >
        {caption && (
          <Text
            $weight={500}
            $size={20}
            $lineHeight={22}
            $padding="0 20px 20px"
            $sm={{
              $lineHeight: 19,
              $size: 16,
            }}
          >
            {caption}
          </Text>
        )}
      </Grid>
    </Grid>
  );
};
