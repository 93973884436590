import { PostType } from 'pn-backend';
import React, { FC, useEffect, useState } from 'react';
import Select, { components, MultiValue, OptionProps } from 'react-select';
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';

import { Grid, Text } from '~atoms';
import { TextColor } from '~utils';

import styles from './styles.module.scss';

export type SelectType = { label: string; value: PostType };

const { Option } = components;

interface Props {
  onChange: (props: SelectType[]) => void;
  postTypes?: PostType[];
}

type OptionType = { label: string; value: PostType };

const options: OptionType[] = [
  { label: 'Новости', value: PostType.NewsFull },
  { label: 'Статьи', value: PostType.Article },
  { label: 'Лонгриды', value: PostType.Longread },
  { label: 'Карточки', value: PostType.Card },
  { label: 'Тесты', value: PostType.Test },
];

const formatGroupLabel = () => (
  <Grid $marginLeft="6px" $marginBottom="10px" $marginTop="10px">
    <Text $weight={500} $size={20} $color="gray" $textTransform="lowercase">
      все материалы
    </Text>
  </Grid>
);

const groupedOptions = [
  {
    label: 'все материалы',
    options,
  },
];

const SelectOption = (props: OptionProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isSelected, label, value } = props;
  return (
    <div>
      <Option {...props}>
        <input
          style={{
            accentColor: 'var(--color-primary)',
            height: '20px',
            width: '20px',
          }}
          id={value}
          name={value}
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
        />
        <Grid>
          <Text $weight={500} $size={20} $color={isSelected ? ('primary' as TextColor) : 'gray'}>
            {label}
          </Text>
        </Grid>
      </Option>
    </div>
  );
};

const MultSelectorValue = (props: ValueContainerProps) => {
  const { selectProps } = props;

  if (Array.isArray(selectProps.value)) {
    const isOne = selectProps?.value?.length === 1;
    const isMoreThanOne = selectProps?.value?.length > 1;
    const isAll = selectProps?.value?.length === options.length;

    let label = '';

    if (isOne) label = `: ${selectProps.value[0].label.toLowerCase()}`;
    if (isMoreThanOne) label = `: ${selectProps?.value?.length}`;
    if (isAll) label = `: все`;

    const text = `тип материала${label}`;

    return (
      <Grid $marginLeft="14px">
        <Text $weight={500} $size={20} $color={'primary' as TextColor}>
          {text}
        </Text>
      </Grid>
    );
  }
  return (
    <Grid $marginLeft="14px">
      <Text $weight={500} $size={20} $color="gray">
        тип материала
      </Text>
    </Grid>
  );
};

export const SearchSelect: FC<Props> = ({ onChange, postTypes }) => {
  const [open, setOpen] = useState(false);

  const selected = options.filter((option) => {
    return postTypes?.find((postType) => option.value === postType);
  });
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const onSelectChange = (value: MultiValue<unknown>) => {
    onChange(value as SelectType[]);
  };

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (e.target) {
        const target = e.target as HTMLElement;
        const closest = target.closest('#select-wrapper');

        if (closest) return;
        if (!closest) onClose();
      }
    };

    window.addEventListener('mouseup', onClickOutside);

    return () => window.removeEventListener('mouseup', onClickOutside);
  }, []);

  return (
    <Grid
      $display="flex"
      $alignItems="center"
      $width="300px"
      id="select-wrapper"
      $zIndex={1000}
      $cursor="pointer"
      onTouchEnd={onOpen}
      onClick={onOpen}
    >
      <Select
        isMulti
        openMenuOnClick
        menuIsOpen={open}
        onMenuOpen={onOpen}
        onMenuClose={onClose}
        value={selected}
        isClearable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        placeholder={<div>Тип материала</div>}
        className={styles.select}
        classNamePrefix="react-select"
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
        onChange={onSelectChange}
        components={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Option: SelectOption,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ValueContainer: MultSelectorValue,
        }}
      />
    </Grid>
  );
};
