import Link from 'next/link';
import React, { FC } from 'react';

import { Grid, Tag, Text } from '~atoms';
import { GridPostCardProps } from '~types';
import { getPostPreviewSummary } from '~utils';

export const CompilationItem: FC<GridPostCardProps> = ({ post }) => {
  const { postLink, tagLink, tagTitle, title } = getPostPreviewSummary(post);

  return (
    <Grid
      $display="flex"
      $flexDirection="column"
      $justifyContent="space-between"
      $gap="20px"
      $lg={{ $minHeight: '88px' }}
      $sm={{ $gap: '0', $minHeight: 'unset' }}
    >
      {title && (
        <Link href={postLink} passHref legacyBehavior>
          <Text
            $size={24}
            $lineHeight={27}
            $weight={600}
            $color="black"
            $hoverColor="primary"
            $cursor="pointer"
            $htmlLineClamp={2}
            $lg={{ $lineHeight: 20, $size: 18 }}
            $sm={{ $marginBottom: '15px' }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>
      )}

      {tagLink && (
        <Link href={tagLink} passHref legacyBehavior>
          <Tag
            $top="0"
            $left="0"
            $right="unset"
            $position="relative"
            $width="fit-content"
            $transparent
            $lg={{
              $fontSize: '14px',
              $lineHeight: '22px',
              $padding: '3px 10px',
              $right: '10px',
            }}
          >
            {tagTitle}
          </Tag>
        </Link>
      )}
    </Grid>
  );
};
