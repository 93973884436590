import Link from 'next/link';
import { BlockType, MoreOnTopicType } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Image, Text } from '~atoms';
import { BlockProps } from '~types';

export const MoreOnTopicBlock: FC<BlockProps<BlockType.MoreOnTopic>> = ({
  compiled: { imageURL, items, type },
}) => {
  switch (type) {
    case MoreOnTopicType.Image:
      return (
        <Link href={items[0].link} passHref legacyBehavior>
          <Grid
            $width="750px"
            $height="240px"
            $margin="40px auto 0"
            $md={{ $padding: '0 15px', $width: '100%' }}
            $sm={{ $height: 'auto', $margin: '30px auto 0' }}
          >
            <Grid
              $padding="20px 0"
              $borderTop="1px solid var(--color-black)"
              $borderBottom="1px solid var(--color-black)"
              $gridTemplateColumns="300px 1fr"
              $columnGap="20px"
              $incutFirstType
              $sm={{ $gridTemplateColumns: '1fr' }}
            >
              <Grid $position="relative" $height="200px" $sm={{ $height: '228px' }}>
                <Grid
                  $zIndex={1}
                  $position="absolute"
                  $width="100%"
                  $height="200px"
                  className="imageOverlay"
                />
                <Image src={imageURL} alt={items[0].caption} />
              </Grid>

              <Grid $alignItems="center" $sm={{ $marginTop: '15px' }}>
                <Text
                  $weight={600}
                  $size={24}
                  $htmlLineClampNoP={7}
                  $lineHeight={29}
                  $sm={{ $lineHeight: 22, $size: 18 }}
                >
                  {items[0].caption}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      );

    case MoreOnTopicType.Text:
      return (
        <Grid
          $width="750px"
          $margin="40px auto 0"
          $md={{ $padding: '0 15px', $width: '100%' }}
          $sm={{ $margin: '30px auto 0' }}
        >
          <Grid $height="54px" $background="black" $border="1px solid var(--color-black)">
            <Text
              $weight={600}
              $size={20}
              $lineHeight={25}
              $textTransform="uppercase"
              $color="white"
              $padding="16px 0 13px 20px"
              $sm={{ $lineHeight: 22, $size: 18 }}
            >
              еще по теме
            </Text>
          </Grid>
          <Grid $border="1px solid var(--color-black)" $padding="0 20px">
            {items.map(({ caption, link }, i) => {
              const isLast = items.length - 1 === i;
              return (
                <Link key={i} href={link} passHref legacyBehavior>
                  <Grid
                    $alignItems="center"
                    $padding="20px 0"
                    $borderBottom={!isLast ? '1px solid var(--color-black)' : undefined}
                  >
                    <Text
                      $weight={600}
                      $size={20}
                      $lineHeight={28}
                      $hoverColor="primary"
                      $cursor="pointer"
                      $sm={{ $lineHeight: 26, $size: 18 }}
                    >
                      {caption}
                    </Text>
                  </Grid>
                </Link>
              );
            })}
          </Grid>
        </Grid>
      );

    default:
      return null;
  }
};
