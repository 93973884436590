import { always, cond, identity, isNil, startsWith, T } from 'ramda';

export type TextColor =
  | 'white'
  | 'black'
  | 'gray'
  | 'blue'
  | 'black-science'
  | 'na-sporte'
  | 'gray-dark'
  | 'gray-light'
  | 'gray-light-dark'
  | 'gray-light-dark-op'
  | 'blue-light'
  | 'blue-light-bg'
  | 'primary'
  | 'secondary'
  | 'social-vk'
  | 'social-telegram'
  | 'social-youtube'
  | 'social-ok'
  | 'social-twitter'
  | 'social-whatsapp'
  | 'alert'
  | 'fail';

export const getColor = cond<any[], string | null>([
  [isNil, always(null)],
  [startsWith('#'), identity],
  [T, (color) => `var(--color-${color})`],
]);
