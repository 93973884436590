// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlockLong: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_168985984022585427',
        ownerId: 1479532,
        params: {
          p1: 'cysla',
          p2: 'imax',
        },
      });
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / Главная / Внутренние страницы - Хэдер 1440х250
  // Категория: <не задана>
  // Тип баннера: Внутренние страницы - Хэдер 1440х250

  return (
    <Grid $margin="0 auto">
      <div id="adfox_168985984022585427" />
    </Grid>
  );
};
