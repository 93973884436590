import Link from 'next/link';
import { SectionPublic } from 'pn-backend';
import { not, propEq } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';

import { Close, SearchIcon } from '~assets';
import { Badge, Grid, Header } from '~atoms';
import { MOBILE_HEADER_HEIGHT } from '~constants';
import { useLayout, useSection, useSvg } from '~hooks';
import { StateContext } from '~providers';
import { getSectionLink } from '~utils';

import { Socials } from '../../../Socials';
import { SearchLine } from '../../SearchLine';
import { HeaderMobile } from '../HeaderMobile';

const isShownInMenu = propEq<keyof SectionPublic>('isShownInMenu', true);

export const HeaderInlineWithLogo: FC = () => {
  const { isAbout, isAdvertisers, isArticle, isCard, isSpecs } = useLayout();

  const [isSimple, setIsSimple] = useState(false);

  useEffect(() => {
    const simpleLong = document?.getElementById('longread_simple');
    if (simpleLong) setIsSimple(true);
  }, []);

  const isBoarded = isSimple || isCard || isArticle || isAbout || isSpecs || isAdvertisers;

  const {
    state: { sections = [], settings },
  } = useContext(StateContext);
  const { logo } = settings;

  const { currentSection } = useSection();
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const toggleSearch = () => setIsOpenSearch(not);
  const closeSearch = () => setIsOpenSearch(false);

  const visibleInMenu = sections.filter((section) => section.isShownInMenu);

  const siteLogo = useSvg(logo?.url);

  return (
    <>
      <Header
        $flexDirection="column"
        $borderBottom={isBoarded ? '1px solid black' : undefined}
        $xl={{ $display: 'none' }}
      >
        <Grid
          $gridAutoFlow="column"
          $margin="auto"
          $maxWidth="1200px"
          $width="100%"
          $justifyContent="space-between"
          $gridAutoColumns="1fr"
          $alignItems="center"
          $gap="40px"
          $xl={{ $maxWidth: '768px', $padding: '0 15px' }}
          $sm={{ $height: MOBILE_HEADER_HEIGHT }}
        >
          <Grid
            $gap="18px"
            $xl={{
              $alignItems: 'center',
              $display: 'flex',
              $justifyContent: 'space-between',
              $width: '100%',
            }}
          >
            <Grid
              $display="flex"
              $alignItems="center"
              $overflow="hidden"
              $width="100%"
              $padding="16px 0"
              $xl={{ $display: 'none' }}
            >
              <Grid $width="150px" $minWidth="150px" $height="20px" $margin="0 30px 0 0">
                <Link href="/" passHref>
                  <div dangerouslySetInnerHTML={{ __html: siteLogo }} />
                </Link>
              </Grid>
              <Grid $display="flex" $width="100%" $justifyContent="flex-start" $overflow="hidden">
                {visibleInMenu
                  .slice(0, 7)
                  .filter(isShownInMenu)
                  .map((section) => (
                    <Badge
                      key={section.alias}
                      $selected={section.alias === currentSection?.alias}
                      $textTransform="lowercase"
                      $fontSize="20px"
                      $lineHeight="22px"
                      $padding="4px 13px"
                    >
                      <Link href={getSectionLink(section.alias)} passHref>
                        {section.title}
                      </Link>
                    </Badge>
                  ))}
              </Grid>
              <Grid $display="flex" $justifySelf="flex-end">
                <Socials inverse inHeader />
                <Grid
                  $width="32px"
                  $height="32px"
                  $marginLeft="10px"
                  $color="black"
                  $cursor="pointer"
                  $userSelect="none"
                >
                  <Grid
                    $transform={isOpenSearch ? 'scale(0)' : 'scale(1)'}
                    $opacity={isOpenSearch ? 0 : 1}
                    $position={isOpenSearch ? 'absolute' : 'relative'}
                    $transition="0.2s ease-in-out"
                  >
                    <SearchIcon id="searchIcon" onClick={toggleSearch} />
                  </Grid>
                  <Grid
                    $width="32px"
                    $transform={isOpenSearch ? 'scale(1)' : 'scale(0)'}
                    $opacity={isOpenSearch ? 1 : 0}
                    $position={isOpenSearch ? 'relative' : 'absolute'}
                    $zIndex={isOpenSearch ? '-10' : '10'}
                    $transition="0.2s ease-in-out"
                  >
                    <Close onClick={toggleSearch} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SearchLine isOpen={isOpenSearch} closeSearch={closeSearch} />
      </Header>
      <HeaderMobile />
    </>
  );
};
