import React, { FC, useLayoutEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { Grid } from '~atoms';

type AdvBlockArticleInlineType = {
  index: number;
};

export const AdvBlockArticleInline: FC<AdvBlockArticleInlineType> = ({ index }) => {
  const [isInView, setIsInView] = useState(false);
  const [isloaded, setIsloaded] = useState(false);

  useLayoutEffect(() => {
    const onLoad = () => setIsloaded(true);

    if (isInView) {
      window.yaContextCb.push(() => {
        // yandex_pn.glavred
        // Площадка: Postnews
        // Тип баннера: 580х400
        // Расположение: середина страницы

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Ya.adfoxCode.createScroll({
          containerId: `adfox_17001258956919789-${index}`,
          onLoad,
          ownerId: 1479532,
          params: {
            p2: 'irxq',
            pp: 'h',
            ps: 'gqer',
          },
        });
      });
    }
  }, [index, isInView]);

  const onEnter = () => {
    setIsInView(true);
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Waypoint onEnter={onEnter} topOffset="200px" />
      <Grid $position="relative" $margin={isloaded ? '40px auto 0' : '0'}>
        <div id={`adfox_17001258956919789-${index}`} />
      </Grid>
    </>
  );
};
