import { useEffect, useState } from 'react';

export const useSvg = (url?: string) => {
  const [svgCode, setSvgCode] = useState('');
  useEffect(() => {
    if (!url) return;

    const storedLogo = localStorage.getItem(url);

    if (storedLogo) {
      setSvgCode(JSON.parse(storedLogo));
      return;
    }

    fetch(url)
      .then((response) => response.text())
      .then((data) => {
        localStorage.setItem(url, JSON.stringify(data));
        setSvgCode(data);
      })
      .catch((error) => {
        console.error('Error fetching SVG:', error);
      });
  }, [url]);

  return svgCode;
};
