import classNames from 'classnames';
import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
} from 'react';

import { Grid } from '~atoms';
import { useIsMain, useLayout, useSection } from '~hooks';
import { AdbBlockBannerMain, AdvBlockLong, AdvBlockLongMain } from '~mocks';
import { StateContext } from '~providers';

import { useMobile } from '../../hooks/useMobile';
import { BrandingLayout } from '../BrandingLayout';
import { Footer } from '../Footer';
import { Header } from '../Header';
import styles from './styles.module.scss';

export const Layout: FC<
  PropsWithChildren & {
    isPShapedBannerVisible: boolean;
    setIsPShapedBannerVisible: Dispatch<SetStateAction<boolean>>;
  }
> = (props) => {
  const { children, isPShapedBannerVisible, setIsPShapedBannerVisible } = props;
  const { isArticle, isCard, isNewsFull, isTest, layout } = useLayout();
  const { isSection } = useSection();

  const {
    state: { settings },
  } = useContext(StateContext);
  const { primaryColor } = settings;

  useEffect(() => {
    console.log('primaryColor', primaryColor);
    document.documentElement.style.setProperty('--color-primary', primaryColor);
    document.documentElement.style.setProperty('--color-primary-light', `${primaryColor}cc`);
  }, [primaryColor]);

  const isMobile = useMobile();
  const isMain = useIsMain();
  const isPost = isArticle || isCard || isTest;

  const isArticlePShapedBannerVisible = !isMobile && isArticle;
  const isMainPShapedBannerVisible = !isMobile && isMain;

  const onPShapedBannerLoad = () => {
    setIsPShapedBannerVisible(true);
  };

  useEffect(() => {
    const banners = document.getElementsByClassName('adfox-banner-background');

    Array.from(banners).forEach((banner) => {
      banner.remove();
    });
    setIsPShapedBannerVisible(false);
  }, [isArticle, isMain, setIsPShapedBannerVisible]);

  return (
    <>
      {typeof isMobile !== 'undefined' && !isMobile && !isPShapedBannerVisible && isPost && (
        <AdvBlockLong />
      )}
      {typeof isMobile !== 'undefined' && !isMobile && !isPShapedBannerVisible && isMain && (
        <AdvBlockLongMain />
      )}

      {isMain && typeof isMobile !== 'undefined' && !isMobile && (
        <AdbBlockBannerMain onLoad={onPShapedBannerLoad} />
      )}

      <Grid
        $margin={isPShapedBannerVisible && isMain ? '250px 0 0' : '0'}
        className={classNames(styles.layout, isSection && styles.isSection, styles[layout])}
      >
        <Header />
        {(isArticlePShapedBannerVisible || isMainPShapedBannerVisible) && (
          <BrandingLayout
            isPShapedBannerVisible={isPShapedBannerVisible}
            setIsPShapedBannerVisible={setIsPShapedBannerVisible}
          >
            <section className={styles.section}>{children}</section>
          </BrandingLayout>
        )}
        {!isPShapedBannerVisible &&
          !(isArticlePShapedBannerVisible || isMainPShapedBannerVisible) && (
            <section className={styles.section}>{children}</section>
          )}
        <Footer />
      </Grid>
    </>
  );
};
