import { prop } from 'ramda';
import React, { FC, useEffect, useState } from 'react';

import { Tooltip } from '../Tooltip';

type Props = {
  html: string;
};

export const Tooltips: FC<Props> = ({ html }) => {
  const [tooltipIds, setTooltipIds] = useState<string[]>([]);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const tooltips = doc.querySelectorAll('[data-tooltip-content]');

    const regex = /data-tooltip-content="[\s\S]{0,}"/gm;
    const regexEmpty = /data-tooltip-content=""/gm;

    const tooltipValues = Array.from(tooltips).map((userItem) => {
      const content = userItem.outerHTML.match(regex);
      const withFirstQuote = userItem.outerHTML.match(regexEmpty);

      // специальный хак для тултипов начинающихся с "
      // При наличии " в начале строки разметка ломается и данные
      // в блоке разметки тултипа отображаются некорректно
      if (withFirstQuote) {
        const result =
          content &&
          content[0].replace('data-tooltip-content="" ', '').replaceAll('=""', '').slice(0, -1);

        if (result) {
          return `"${result[0].toUpperCase()}${result.slice(1)}`;
        }

        return result;
      }

      // Убирает убирает data-tooltip-content=" спереди и " сзади
      return content && content[0].replaceAll('=""', '').slice(22, -1);
    });

    const tooltipIdsArray = Array.from(tooltips.values()).map(prop('id'));

    tooltipIdsArray.forEach((tooltipId, i) => {
      document
        .getElementById(tooltipId)
        ?.setAttribute('data-tooltip-content', tooltipValues[i] || '');
    });

    setTooltipIds(tooltipIdsArray);
  }, [html]);

  return (
    <>
      {tooltipIds.map((tooltipId) => (
        <Tooltip key={tooltipId} tooltipId={tooltipId} />
      ))}
    </>
  );
};
