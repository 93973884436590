import { pathOr, propOr } from 'ramda';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { devices, Screen } from '~constants';

type H3Props = {
  $hyphens?: CSSProperties['hyphens'];
  $lineHeight?: CSSProperties['lineHeight'];
  $size?: number;
  $textTransform?: CSSProperties['textTransform'];
  $weight?: CSSProperties['fontWeight'];
  text?: string;
};

type Props = H3Props & { [key in Screen]?: H3Props };

export const H3 = styled.h3<Props>`
  font-weight: ${propOr('600', '$weight')};
  font-size: ${propOr(25, '$size')}px;
  text-transform: ${propOr('inherit', '$textTransform')};
  line-height: 120%;
  margin: 0;

  & a {
    color: var(--color-primary);
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid var(--color-primary);

    &:hover {
      border-color: transparent;
    }
  }

  .highlight {
    background: var(--color-secondary);
  }

  .textTooltip {
    padding: 3px 8px 0;
    border-radius: 34px;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    color: var(--color-primary);

    &:hover {
      border: 1px solid var(--color-black);
      color: var(--color-black);
    }
  }

  ${(props) =>
    Object.entries(devices).map(
      ([field, width]) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[field] &&
        css`
          @media only screen and (max-width: ${width}) {
            font-size: ${pathOr(null, [field, '$size'], props)}px;
            line-height: ${pathOr(null, [field, '$lineHeight'], props)}px;
            hyphens: ${pathOr(null, [field, '$hyphens'], props)};
          }
        `,
    )}
`;
